import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image2 from "../../images/blog-15-image1.jpg"
import image from "../../images/blog-15-image2.png"

export default function FiveTipsToDitchYourBackPain(){
    return(
        <BlogPostOld
            src={image}
            title={"If Exercise is Painful, Should I Stop?"}
            summary={"Many people (especially when recovering from an injury or in pain) believe that if they have pain during an exercise, it is doing harm. However, this is not necessarily true. Pain is a poor indication of tissue damage so relying on the sensation of pain alone can be very misleading. "}
            date={"July 11, 2022"}
            author={"Dr. Donald Mull, DC"}
        >
            <p>Many people (especially when recovering from an injury or in pain) believe that if they have pain during an exercise, it is doing harm. However, this is not necessarily true. Pain is a poor indication of tissue damage so relying on the sensation of pain alone can be very misleading. </p>
            <p>Physical activity does not need to be pain free for it to be beneficial. Pain does not mean there is harm being placed on the body part eliciting a painful sensation. </p>
            <p>Think of pain like a warning signal, similar to a smoke detector. A smoke detector can go off when you are burning toast. Do you evacuate the house and call the fire department everytime you burn toast?</p>
            <p>Instead of aiming to be pain free with your exercises or physical activity, aim for it to be tolerable. </p>
            <p>You don’t need to put yourself through miserable pain, but expect there to be low to moderate levels of discomfort. If it is tolerable and it gets better with reps or duration, that is a good sign damage is not being done. </p>
            <p>We like to use the stop-light method when it comes to deciding on whether to continue or modify. It is a great way to monitor pain because it is very simple and gives you a lot of wiggle room to get back to doing the things you love to do! Use the below picture as guidance: green means go, yellow means proceed with caution and red means stop/modify. This was adapted from the works of <a href="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/9413448/&amp;sa=D&amp;source=editors&amp;ust=1657568538457651&amp;usg=AOvVaw0YT5tdYac4K_9puEpJMKNU">Thommee (1997)</a> and<a  href="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/17307888/&amp;sa=D&amp;source=editors&amp;ust=1657568538457979&amp;usg=AOvVaw2AJfHZYE6OHW3CEgrVCOyG">Sibernagel (2007)</a></p>
            <img src={image2} alt={"graph"}/>
            <p>Don’t avoid everything, do what you can</p>
            <p>In conclusion, it is a very risky thing to avoid everything. You then become deconditioned and less prepared to do things you love. This can increase the risk of injuring yourself when you come back because activity increases too fast too soon. Take your time and slowly progress. Ask for help if you need it, we all need and benefit from support. </p>
            <p>Navigating through pain can be very overwhelming and it can be very beneficial to get guidance from a clinician who is willing to support you and be in your corner. If you’d like to consult with us to have that support <a href={"https://kineticimpact.janeapp.com/"}>click here</a> and let’s get started!</p>
            <p>References:</p>
            <p>Thomeé R. A comprehensive treatment approach for patellofemoral pain syndrome in young women. Phys Ther. 1997 Dec;77(12):1690-703. doi: 10.1093/ptj/77.12.1690. PMID: 9413448</p>
            <p>Silbernagel KG, Thomeé R, Eriksson BI, Karlsson J. Continued sports activity, using a pain-monitoring model, during rehabilitation in patients with Achilles tendinopathy: a randomized controlled study. Am J Sports Med. 2007 Jun;35(6):897-906. doi: 10.1177/0363546506298279. Epub 2007 Feb 16. PMID: 17307888.</p>
        </BlogPostOld>

    )
}